<template>
  <div class="panel panel-default">
    <div class="panel-heading">全国桑园土壤管理 > 新增土壤数据</div>
    <div class="panel-body">
      <div class="table-responsive input-form">
        <p><b>基本信息</b></p>
        <div class="table-responsive">
          <table class="table table-bordered table-base">
            <thead>
              <tr class="th">
                <th>省</th>
                <th>市</th>
                <th>县（区）</th>
                <th>乡（镇）</th>
                <th>村组</th>
                <th>采样点编号</th>
                <th>编号</th>
                <th>经度</th>
                <th>纬度</th>
                <th>农户姓名</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select v-model="province">
                    <option
                      v-for="item in sangyuanProvinceArr"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </td>
                <td><input type="text" v-model="city" /></td>
                <td><input type="text" v-model="county" /></td>
                <td><input type="text" v-model="town" /></td>
                <td>
                  <input type="text" v-model="village"/>
                </td>
                <td><input type="text" v-model="iD_point" /></td>
                <td><input type="text" v-model="iD_sample" /></td>
                <td><input type="text" v-model="e_sample" /></td>
                <td><input type="text" v-model="n_sample" /></td>
                <td><input type="text" v-model="name_sample" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <br />

      <div class="table-responsive input-form">
        <p><b>桑园采样点层次信息</b></p>
        <div class="table-responsive">
          <table class="table table-bordered table-detail">
            <thead>
              <tr class="th">
                <th>土层(cm)</th>
                <th>送样编号</th>
                <th>pH(土水比1：1)</th>
                <th>有机质(g/kg)</th>
                <th>全氮(g/kg)</th>
                <th>全磷(g/kg)</th>
                <th>全钾(g/kg)</th>
                <th>有效磷(mg/kg)</th>
                <th>速效钾(mg/kg)</th>
                <th>有效硫(mg/kg)</th>
                <th>有效钙(mg/kg)</th>
                <th>有效镁(mg/kg)</th>
                <th>有效锌(mg/kg)</th>
                <th>有效硼(mg/kg)</th>
                <th>石砾含量(%)</th>
                <th>总盐度(g/kg)</th>
                <th>有效铁(mg/kg)</th>
                <th>总硒含量(mg/kg)</th>
                <th>有效铜(mg/kg)</th>
                <th>氯离子(mg/kg)</th>
                <th>有效锰(mg/kg)</th>
                <th>有效钼(mg/kg)</th>
                <th>总铅(mg/kg)</th>
                <th>总镉(mg/kg)</th>
                <th>有效镉(mg/kg)</th>
                <th>总铬(mg/kg)</th>
                <th>总砷(mg/kg)</th>
                <th>汞(mg/kg)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in MulberryLayerMessageList"
                :key="index"
              >
                <td><input type="text" v-model="item.soil_depth" /></td>
                <td><input type="text" v-model="item.soil_ID_test" /></td>
                <td><input type="text" v-model="item.pH" /></td>
                <td><input type="text" v-model="item.sOM" /></td>
                <td><input type="text" v-model="item.sTN" /></td>
                <td><input type="text" v-model="item.sTP" /></td>
                <td><input type="text" v-model="item.sTK" /></td>
                <td><input type="text" v-model="item.sAP" /></td>
                <td><input type="text" v-model="item.sAK" /></td>
                <td><input type="text" v-model="item.sAS" /></td>
                <td><input type="text" v-model="item.sACa" /></td>
                <td><input type="text" v-model="item.sAMg" /></td>
                <td><input type="text" v-model="item.sAZn" /></td>
                <td><input type="text" v-model="item.sAB" /></td>
                <td><input type="text" v-model="item.sTC" /></td>
                <td><input type="text" v-model="item.sTS" /></td>
                <td><input type="text" v-model="item.sAFe" /></td>
                <td><input type="text" v-model="item.sTSe" /></td>
                <td><input type="text" v-model="item.sACu" /></td>
                <td><input type="text" v-model="item.sACl" /></td>
                <td><input type="text" v-model="item.sAMn" /></td>
                <td><input type="text" v-model="item.sAMo" /></td>
                <td><input type="text" v-model="item.sTPb" /></td>
                <td><input type="text" v-model="item.sTCd" /></td>
                <td><input type="text" v-model="item.sACd" /></td>
                <td><input type="text" v-model="item.sTCr" /></td>
                <td><input type="text" v-model="item.sTAs" /></td>
                <td><input type="text" v-model="item.hg" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <input
          type="submit"
          class="btn btn-default my-submit"
          value="增加层次"
          @click="addRow()"
        /><span v-html="'\u00a0\u00a0\u00a0'"></span>
        <input
          type="submit"
          class="btn btn-default my-submit"
          value="减少层次"
          @click="removeRow()"
        />
      </div>

      <br />
      <input
        type="submit"
        class="btn btn-default my-submit"
        value="提交"
        @click="handleSubmit()"
      />
      <!-- 表单校验 -->
      <span v-if="errors.length">
        <b> 请检查下列问题: </b
        ><span v-for="error in errors" :key="error">{{ error }} </span>
      </span>
    </div>
  </div>
</template>

<script>
import globalData from "@/global.js";
import axios from "axios";
import qs from "qs";

export default {
  data() {
    return {
      sangyuanProvinceArr: globalData.sangyuanProvinceArr,
      // 表单校验
      errors: [],
      // 基本信息
      id: "",
      iD_point: "",
      iD_sample: "",
      e_sample: "",
      n_sample: "",
      name_sample: "",
      province: "",
      city: "",
      county: "",
      town: "",
      village: "",
      // 桑园采样点层次信息
      MulberryLayerMessageList: [],
    };
  },
  methods: {
    handleSubmit() {
      const {
        iD_point,
        iD_sample,
        e_sample,
        n_sample,
        name_sample,
        province,
        city,
        county,
        town,
        village,
        MulberryLayerMessageList,
      } = this;

      let baseData = {
        iD_point,
        iD_sample,
        e_sample,
        n_sample,
        name_sample,
        province,
        city,
        county,
        town,
        village,
      };

      console.log(baseData);

      // 表单校验
      this.errors = [];

      if (!this.province) {
        this.errors.push("必填省;");
      }
      if (this.province) {
        let promiseArr = [];

        // 基本信息
        const mulberryMessagePromise = new Promise((resolve, reject) => {
          axios
            .post(`./data_mulberry/addMulberryMessage`, qs.stringify(baseData))
            .then((res) => {
              if (res.data.code != 200) {
                reject();
              }
              this.id = res.data.data.id;
              // 更新数据
              this.$store.dispatch("getSangyuanListAction");
              resolve();
            });
        });

        mulberryMessagePromise
          .then(() => {
            if (MulberryLayerMessageList.length == 0) return;
            for (let i = 0; i < MulberryLayerMessageList.length; i++) {
              const element = MulberryLayerMessageList[i];
              element.id = this.id;
              promiseArr.push(
                new Promise((resolve, reject) => {
                  axios
                    .post(
                      `./data_mulberry/addMulberryLayerMessage`,
                      qs.stringify(element)
                    )
                    .then((res) => {
                      if (res.data.code != 200) reject();
                      resolve();
                    });
                })
              );
            }
            return Promise.all(promiseArr);
          })
          .then(() => {
            // 页面跳转
            this.$message({
              message: "新增土壤数据成功",
              type: "success",
            });
            this.$router.push(`/sangyuanlist`);
          })
          .catch(() => {
            this.$message.error("操作失败, 请检查服务器");
          });
      }
    },
    addRow() {
      this.MulberryLayerMessageList.push({
        soil_depth: "",
        soil_ID_test: "",
        pH: "",
        sOM: "",
        sTN: "",
        sTP: "",
        sTK: "",
        sAP: "",
        sAK: "",
        sAS: "",
        sACa: "",
        sAMg: "",
        sAZn: "",
        sAB: "",
        sTC: "",
        sTS: "",
        sAFe: "",
        sTSe: "",
        sACu: "",
        sACl: "",
        sAMn: "",
        sAMo: "",
        sTPb: "",
        sTCd: "",
        sACd: "",
        sTCr: "",
        sTAs: "",
        hg: "",
      });
    },
    removeRow() {
      const len = this.MulberryLayerMessageList.length;
      if (len == 0) return;
      this.MulberryLayerMessageList.splice(len - 1, 1);
    },
  },
};
</script>

<style scoped>
.table {
  margin-bottom: 0px;
}
.my-submit {
  margin-top: 7px;
}

.table-base select,
.table-base input {
  width: 150px;
}
select {
  padding: 1px 2px;
  height: 24px;
}

.table-detail input {
  width: 100px;
}
</style>